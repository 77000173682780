<template>
<div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn  label="顾客姓名" prop="customer_name" />
            <ElTableColumn  label="手机号" prop="telephone" />
            <ElTableColumn label="门店" prop="shop_name" />
			<ElTableColumn label="成交时间" prop="cj_timet" />
            <ElTableColumn  label="成交类型" prop="business_type">
            <!-- <template slot-scope="{ row }">
                  <div>{{row.business_type == 1?'疤痕':row.business_type == 2?'疤痕':''}}</div>
                </template> -->
            </ElTableColumn>
			<ElTableColumn label="成交金额" prop="cj_amounty" />
            <ElTableColumn  label="疤痕二次消费率|项目普及率|功效满意">
                <template slot-scope="{ row }">
                  <div>{{row.is_second == 1?'否':'是'}}</div>
                </template>
            </ElTableColumn>
            
          </el-table>
          <!-- <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          /> -->
        </el-col>
      </el-row>
  </div>
</template>
<script>
import pagination from '@/components/Pagination'
import { readAdminCustomer } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: 'ConsumeDetail',
   components: {
    pagination
  },
  data () {
    return {
      list: [], // 数据展示
      page: new Page(), // 分页
      type:''
    }
  },
  mounted () {
    // this.type = this.$route.query.type
    //console.log(this.$route.query);
    this.getList();
  },
  methods: {
    getList() {
      readAdminCustomer({ ...this.page, ...this.$route.query}).then(res => {
        this.list = res.data.list;
        // this.list.forEach((val) => {
        //   val['shop_name'] = res.data.shop_name;
        // });
        this.page.total = res.data.dataCount;
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.reveal{
  text-align: right;
  padding: 20px 0;
  .peril{
    font-weight: bold;
  }
}
.btnBox{
  text-align: right;
}
</style>
